<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card>
      <div slot="no-body" class="full-page-bg-color">
        <iframe
          width="100%"
          height="600"
          :src="meeting_link"
          allow="camera *;microphone *"
          v-if="meeting_link"
          allowfullscreen
        ></iframe>
        <div class="p-10" v-else>
          <img
            src="logo.png"
            :alt="`${$t('basic.Logo')}`"
            class="block m-auto mb-5"
          />
          <h3 class="text-center text-color-base">{{ siteTitle }}</h3>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>

// Validator
import axios from 'axios'


export default {


  data () {
    return {
      meeting_link: ''
    }
  },
  created () {
    this.joinMeeting()
  },
  methods: {
    joinMeeting () {
      axios.get(`/bbb-integration/join-quick-meeting/${this.$route.params.meeting_id}`).then(res => {
        this.meeting_link = res
      })

    }
  },
  computed: {

  }
}
</script>
<style lang="scss">
.transition {
  transition: all 0.5s ease;
}

.register-tabs-container {
  max-height: 700px;
  overflow-y: auto;

  .con-tab {
    padding-bottom: 23px;
  }

  select,
  label,
  button span,
  .vs-select--options span {
    text-transform: capitalize;
  }
}

.vue-form-wizard.md .wizard-navigation .wizard-nav .wizard-icon-circle {
  width: 45px;
  height: 45px;

  .wizard-icon {
    font-size: 1.2rem;
  }
}

span.text-danger {
  text-transform: capitalize;
  font-size: 0.875rem !important;

  &.w-full {
    display: block;
  }
}

.fullscreen {
  position: absolute;
  right: 15px;
  top: 10px;

  .vs-button-primary.vs-button-filled {
    background-color: #eff2f7 !important;
    color: #2c2c32;
  }
}
</style>
